// Medium blog post about importing SVG's as a react component in typescript.
//https://jairorlandohuamanbellido.medium.com/import-icon-as-component-instead-as-a-svg-file-react-typescript-d485aa71c17
import React from 'react';
//import ResumeSVG from './assets/resume.svg';
import resume from "./assets/resume.png";
import './resume.css';

const Resume = () => {

    return<div className='resume_main'>
        <img src={resume} alt="Resume" />
    </div>
};

export default Resume