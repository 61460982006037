// Drawable canvas blog link
// https://www.ankursheel.com/blog/react-component-draw-page-hooks-typescript
// Digit classifier react app.
// https://medium.com/@rhome/deep-learning-web-app-63904aea7a4e
import React, { useCallback, useEffect, useRef, useState } from 'react';
import "./canvas.css";

interface CanvasProps {
    width: number;
    height: number;
}

type Coordinate = {
    x: number;
    y: number;
};
// Define the Canvas component function.
const Canvas = ({ width, height }: CanvasProps) => {
    //console.log("Top of canvas component function.");
    // Initialize the reference to the DOM to get direct access.
    // canvasRef is typed as an HTML canvas element with no data.
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isPainting, setIsPainting] = useState(false);
    const[prediction, setPrediciton] = useState(0);
    const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(undefined);
    // Create reference to manipulate smaller canvas node to preview NN input image.
    const NNcanvasRef = useRef<HTMLCanvasElement>(null);

    const startPaint = useCallback((event: MouseEvent) => {
        //console.log("top of startPaint.");
        const coordinates = getCoordinates(event);
        // TODO: Why is this conditional used if it's assigned to in the preceeding line?
        if (coordinates) {
            setMousePosition(coordinates);
            setIsPainting(true);
        }
    }, []);

    useEffect(() => {
        //console.log("useEffect for mousedown event.");
        // Check if canvasRef.current is defined before referencing the HTML element.
        if (!canvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mousedown', startPaint);
        //console.log("added event listener in userEffect hook.");
        return () => {
            canvas.removeEventListener('mousedown', startPaint);
            //console.log("removed event listener.");
        };
    }, [startPaint]);

    const paint = useCallback((event: MouseEvent) => {
            if (isPainting) {
                const newMousePosition = getCoordinates(event);
                // getCoordinates might return undefined.
                if (mousePosition && newMousePosition) {
                    drawLine(mousePosition, newMousePosition);
                    setMousePosition(newMousePosition);
                }
            }
        },[isPainting, mousePosition]
    );

    useEffect(() => {
        //console.log("useEffect for mousemove event.");
        if (!canvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mousemove', paint);
        return () => {
            canvas.removeEventListener('mousemove', paint);
        };
    }, [paint]);

    const exitPaint = useCallback(() => {
        setIsPainting(false);
        setMousePosition(undefined);
    }, []);

    useEffect(() => {
        if (!canvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mouseup', exitPaint);
        canvas.addEventListener('mouseleave', exitPaint);
        return () => {
            canvas.removeEventListener('mouseup', exitPaint);
            canvas.removeEventListener('mouseleave', exitPaint);
        };
    }, [exitPaint]);

    // A function 'getCoordinates' that takes as a parameter named 'event' of type 'MouseEvent'...Top


    // returning EITHER a coordinate or and 'undefined' object.
    const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
        //console.log("getCoordinates.");
        if (!canvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef.current;
        //console.log("(pageX,pageY):", event.pageX, event.pageY);
        //console.log("coordinate:", event.pageX - canvas.offsetLeft, event.pageY - canvas.offsetTop);
        return { x: event.pageX - canvas.offsetLeft, y: event.pageY - canvas.offsetTop };
    };

    const drawLine = (originalMousePosition: Coordinate, newMousePosition: Coordinate) => {
        //console.log("top of drawLine.");
        if (!canvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
            context.strokeStyle = 'black';
            context.lineJoin = 'round';
            context.lineWidth = 15;

            context.beginPath();
            context.moveTo(originalMousePosition.x, originalMousePosition.y);
            context.lineTo(newMousePosition.x, newMousePosition.y);
            context.closePath();

            context.stroke();
        }
    };

    function submitCanvasData():void {
        //console.log("getCanvasData");
        if (!canvasRef.current || !NNcanvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const context = canvas.getContext('2d');
        if(context){
            const copyCanvas = document.createElement('canvas');
            const copyContext = copyCanvas.getContext('2d');
            copyCanvas.width = 28;
            copyCanvas.height = 28;
            if(!copyContext){
                console.log("copyContext undefined.");
                return;
            }
            // Redraw canvas image to scale down to 20x20 pixels.
            copyContext.drawImage(canvas, 0, 0, 28, 28);
            // Get alpha values from scaled down image.
            const pixelData = copyContext.getImageData(0, 0, 28, 28).data.filter((e, i) => i % 4 === 3);
            // Pass scaled copy to be rendered in preview canvas.
            renderPreview(copyCanvas);
            // console.log(imgData);
            // console.log(nnInput);
            //console.log(pixelData)
            
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://195.35.36.252:443', true);
            xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
            xhr.onreadystatechange = function(){
                if(xhr.readyState === XMLHttpRequest.DONE){
                    //console.log("request complete.")
                    const result = JSON.parse(xhr.responseText);
                    //console.log(result.digit);
                    setPrediciton(result.digit);
                }
            }
            xhr.send(JSON.stringify({image: pixelData, imageWidth: copyCanvas.width, imageHeight: copyCanvas.height}));
        }
        //return;
    }
    // Draw scaled down image to NN input canvas
    function renderPreview(smallImg: HTMLCanvasElement):void {
        if (!canvasRef.current || !NNcanvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }
        // Get element reference to preview canvas.
        const previewCanvas: HTMLCanvasElement = NNcanvasRef.current;
        // Create drawing context object for preview canvas.
        const previewContext = previewCanvas.getContext('2d');
        const context = smallImg.getContext('2d');
        if(context && previewContext){
            // Scale 20x20 image back up to size of preview canvas.
            previewContext.drawImage(smallImg, 0, 0, previewCanvas.width, previewCanvas.height);
        }
    }

    function resetCanvas():void {
        if (!canvasRef.current || !NNcanvasRef.current) {
            console.log("!canvasRef.current");
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const context = canvas.getContext('2d');
        const prevCanvas: HTMLCanvasElement = NNcanvasRef.current;
        const prevContext = prevCanvas.getContext('2d');
        if(context){
            context.clearRect(0, 0, width, height);
            prevContext?.clearRect(0,0, 200,200);
            setPrediciton(0);
        }
    }
    // Debug function called by button on page to test if nginx server is reachable.
    function testGet():void{
        const xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://195.35.36.252:443', true);
        xhr.send();
    }
    
    return <div className='main_canvas'>
                <canvas className='drawingCanvas' ref={canvasRef} height={height} width={width} />
                <div className='previewCanvas'>
                    <p>Preview</p>
                    <canvas className='NNcanvas' ref={NNcanvasRef} height={100} width={100}/>
                    <p>Prediction: {prediction}</p>
                </div>
                <button onClick={resetCanvas}>
                    Reset
                </button>
                <button onClick={submitCanvasData}>
                    Submit
                </button>
                <button onClick={testGet}>
                    GET
                </button>
            </div>;
};

Canvas.defaultProps = {
    width: window.innerWidth,
    height: window.innerHeight,
};

export default Canvas;