import React from 'react';

const Header = () => {

    return<div>
        <br />
        <a href='https://www.linkedin.com/in/c-dylan-lewis/' target='_blank' rel='noopener'>LinkedIn</a>
        <br />
        <a href='https://github.com/DylanL826' target='_blank' rel='noopener'>Github</a>
        <br />
        <a href='https://leetcode.com/Dylan_L/' target='_blank' rel='noopener'>Leetcode</a>
    </div>
};

export default Header;